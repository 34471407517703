.standalone-text-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10rem 5rem;

  @media only screen and (max-width: 768px) {
    padding: 10rem 1rem;
  }
}
