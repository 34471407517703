.pivital-display-container {
	display: flex;
	background-color: #252525;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;

	.center-content {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 2rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.text-section {
		width: 55%;
		display: flex;
	}

	.image-section {
		width: 45%;
		display: flex;
	}

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	h2,
	p {
	}

	h2,
	p,
	.button-container {
		width: 55%;
		margin-left: auto;
		text-align: left;
		color: white;
		font-weight: 700;
	}

	p,
	.button-container {
		margin-top: 1rem;
		display: flex;
	}
	hr {
		margin-top: 1rem;
	}
}
@media only screen and (max-width: 768px) {
	.pivital-display-container {
		.text-section {
			width: 100%;
		}
		.image-section {
			width: -webkit-fill-available;
			display: flex;
			margin-left: 1rem;
		}
		img {
			width: 100%;
		}
		h2,
		p,
		.button-container {
			margin-left: 1rem;
			width: auto;
		}
	}
}
