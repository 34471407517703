.live-stream-container {
	.countdown-container {
		margin-top: 0rem;
		p {
			margin-top: 0 !important;
		}
	}
	display: flex;

	.content-side {
		width: 100%;
		padding: 2rem;
		display: flex;
		flex-direction: column;

		h3 {
			margin-top: 1rem !important;
		}
	}

	.twitch-container {
		margin: 0 auto 0 auto;
	}

	.schedual-side {
		width: 25%;
		background-color: #efeff0;
		padding: 1rem;

		h3:not(:first-of-type) {
			margin-top: 1rem;
		}

		p,
		h4 {
			color: white;
		}
	}

	.scedual-container {
		display: flex;
		flex-direction: column;
		padding-top: 0rem;
		padding: 1rem;
		background-color: #282c34;
		border-radius: 9px;
		margin-top: 1rem;

		p {
			margin-top: 0.5rem;
		}
	}

	h4 {
		color: white;
	}

	p,
	h4,
	h3 {
		text-align: left;
	}

	.subtext {
		font-weight: 700;
	}

	.content-side {
		h3,
		p:not(:first-of-type) {
			color: white;
			margin-top: 0.5rem;
		}
		p:nth-of-type(1) {
			margin-top: 1rem;
		}
	}
}
@media only screen and (max-width: 768px) {
	.live-stream-container {
		flex-direction: column;

		.content-side {
			width: 100%;
		}

		.schedual-side {
			width: 100%;
		}
	}
}
