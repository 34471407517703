.email_container {
  label {
    text-align: left;
    margin: 1rem 0 0 0;
  }

  textarea {
    margin-top: 0.5rem;
  }
  button {
    margin-top: 0.5rem;
  }
}
