.fixed-background-container {
	height: 100vh;
	width: 100%;
	position: fixed;
	z-index: -15;
	top: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
