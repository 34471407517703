@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #282c34;
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-footer-color {
  background-color: #efeff0;
}
