.nav-container {
  display: flex;
  width: 100%;
  background-color: #efeff0;

  .brand {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    img {
      width: auto;
      height: 3rem;
    }
  }

  ul {
    display: flex;
  }
  ul:first-of-type {
    margin-left: 2rem;
  }
  ul:last-of-type {
    margin-right: 2rem;
  }
  li {
    height: min-content;
    margin: auto;
  }

  .left-nav {
    margin-right: auto;

    li:not(:last-of-type) {
      margin-left: 1rem;
    }
  }
  .right-nav {
    margin-left: auto;

    li:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  .Nav-Menu-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #efeff0;
    z-index: 1000;
    overflow-y: scroll;
    ul {
      display: flex;
      flex-direction: column;
    }

    ul.nav-menu-items-container {
      li {
        font-size: 1.75rem;
        margin: 1.5rem 0 0 0;
        text-align: right;
      }
      li:nth-of-type(1) {
        margin: 2rem 0 0 0;
      }
    }
  }

  .mobile-right-nav {
    button {
      color: black;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
  }
}
