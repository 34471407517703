.landing-screen-container {
	height: 110vh;
	position: relative;

	.content-container {
		margin: auto;
		display: flex;
		height: 95vh;
		position: relative;
	}
	.center-content {
		display: flex;
		flex-direction: column;
		margin: auto;
	}
	.watch-us-live-button {
		margin-top: 1rem;
	}
	h1,
	h3,
	p {
		color: white;
		text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
	}

	h1,
	h3 {
		margin-left: 15%;
		margin-right: 15%;
		line-height: 1;
	}

	h3 {
		margin-top: 1rem;
	}

	.countdown-area-container {
		position: absolute;
		display: flex;
		flex-direction: column;
		bottom: 3.25rem;
		left: 2rem;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 768px) {
	.landing-screen-container {
		h1 {
			font-size: 3rem;
		}

		.content-container {
			height: 90vh;
		}

		.countdown-area-container {
			bottom: 0;
		}
	}
}
