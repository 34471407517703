.decition-container {
	height: 100vh;
	display: flex;
	.center-content {
		display: flex;
		margin-top: auto;
		margin-bottom: auto;
		flex-direction: column;
	}
	.decition-container,
	.titles-section,
	.decitions {
		margin: auto auto auto 5rem;
	}
	p {
		color: white;
		text-align: left;
	}

	.decitions {
		display: flex;

		li {
			display: flex;
			flex-direction: column;
			width: max-content;
		}
		li:not(:last-of-type) {
			margin-right: 1rem;
		}

		img {
			width: 10rem;
			height: 10rem;
			object-fit: cover;
			margin-top: 1rem;
		}
	}

	hr {
		width: 100%;
		height: 1px;
	}

	h1 {
		color: white;
	}
}

@media only screen and (max-width: 768px) {
	.decition-container {
		.titles-section {
			margin-left: 1rem;
		}
		.decitions {
			margin-left: 1rem;
		}
		h1 {
			text-align: left;
		}
	}
}
