.semi_container {
  width: 100%;
  background-color: white;
  height: 95vh;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;

  .inner_container {
    width: 100%;
    height: auto;
    margin: auto 0;
    display: flex;
    flex-direction: column;
  }

  h1 {
    text-align: center;
    margin: 0;
  }

  p {
    text-align: left;
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    .inner_container {
      margin: 2rem 0;
    }
  }
}
