.pivital-display-02-container {
	display: flex;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;

	.img-bg-block {
		width: 100%;
		height: 90%;
		z-index: -5;
		position: absolute;
		top: -1;
		background-color: #757575;
		margin-top: -2.5rem;
	}

	.center-content {
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 3rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.text-section {
		width: 60%;
		display: flex;
		background-color: #efeff0;
		margin-top: -2.5rem;
		margin-bottom: -2.5rem;
	}

	.image-section {
		width: 40%;
		display: flex;
		position: relative;
		margin-right: auto;
	}

	img {
		width: 90%;
		height: 20rem;
		object-fit: cover;
		margin-right: -1rem;
		margin-left: auto;
	}

	h2,
	p {
	}

	h2,
	p,
	.button-container {
		width: 65%;
		margin-right: auto;

		font-weight: 700;
	}

	p,
	.button-container {
		margin-top: 1rem;
		display: flex;
		text-align: left;
	}
	hr {
		margin-top: 1rem;
	}
	h2 {
		line-height: 1.1;
	}
	.subtitle {
		font-size: 0.75rem;
		color: #90c23e;
	}
}

@media only screen and (max-width: 768px) {
	.pivital-display-02-container {
		flex-direction: column;

		.img-bg-block {
			width: 100%;
			background-color: #252525;
			height: 20rem;
		}

		.image-section {
			width: 100%;
		}

		img {
			margin: 0 1rem;
		}

		.text-section {
			width: 100%;
		}

		.center-content {
			margin: 0 1rem;
			padding-top: 3rem;
			padding-bottom: 4rem;
		}

		h2,
		p,
		.button-container {
			width: 100%;
		}

		h2 {
			margin-top: 1rem;
		}
	}
}
