.fluid-vignette-container {
	position: absolute;
	width: 100%;
	height: 101%;
	z-index: -5;
	opacity: 0.6;

	.vignette-top {
		position: absolute;
		top: 0;
		height: 50%;
		width: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	}
	.vignette-bottom {
		position: absolute;
		bottom: 0;
		height: 50%;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	}
}
