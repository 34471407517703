.fluid-video-background {
	object-fit: cover;
	width: 100%;
	height: 100%;

	position: absolute;
	display: flex;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.fluid-video-container {
	width: 100%;
	height: 100%;
	z-index: -10;
	position: absolute;
	z-index: -11;
}
