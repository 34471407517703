.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: transparent;
  background-position: center;
  height: 665px;
  background-repeat: no-repeat;
  background-color: #efeff0;
  background-repeat: no-repeat;
  background-size: cover;
  .nav {
    margin: auto 0;
    padding: 0rem 1rem;
  }

  .bg {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: -2;
  }

  .upper-content {
    z-index: 1;
    width: 100%;
    height: 400px;
    display: flex;
  }

  .lower-content {
    height: 265px;
    display: flex;
    section {
      width: 33%;
      display: flex;
      flex-direction: column;
    }
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .center-content {
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .green-block {
    top: 1.75rem;
    left: -0.2rem;
    width: 25rem;
    position: absolute;
    height: 1.25rem;
    background-color: #90c23e;
    z-index: -2;
  }

  h1,
  h2 {
    color: white;
  }

  .logo {
    img {
      width: 80%;
      height: auto;
      margin: auto;
    }
  }

  .hor-list {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    li:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  .links {
    margin-top: 1rem;
    display: flex;
  }

  .icons {
    margin-top: 1rem;

    img {
      height: 1.75rem;
      width: auto;
    }
  }

  .center-text {
    margin-top: 2rem;
  }
  .contact {
    margin: auto 0;
    ul {
      margin-top: 2rem;
    }
    li:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
  .footer-countdown-container {
    margin: 0 auto;
    display: flex;
    padding: 1rem 1.5rem;
    background-color: #90c23e;
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    margin: 0;
  }
  .nav {
    margin: 0;
  }
  .footer-container {
    .lower-content {
      .logo {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .extra-footer-container {
    margin: -1rem 0rem;
    display: flex;
    flex-direction: column;
    background-color: #efeff0;
    padding: 1rem 1rem 2rem 1rem;
  }

  li {
    margin-top: 1rem;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: auto;
  }

  .icons {
    display: flex;
    flex-direction: row;
    li:first-of-type {
      margin-left: auto;
      margin-right: 1rem;
    }
    li:last-of-type {
      margin-right: auto;
      margin-left: 1rem;
    }
  }

  .green-block {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }

  h2 {
    /* 	line-height: 1; */
    letter-spacing: -1px;
    font-size: 2.75rem;
  }
}
